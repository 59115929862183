import { activateHoverCursor, deactivateHoverCursor } from '../../../modules/common'
import twitter_icon_wh from '../../../assets/img/header/twitter_wh.svg';
import twitter_icon_cl from '../../../assets/img/header/twitter_cl.svg';
import youtube_icon_wh from '../../../assets/img/header/youtube_wh.svg';
import youtube_icon_cl from '../../../assets/img/header/youtube_cl.svg';
import apple_icon_wh from '../../../assets/img/header/apple_wh.svg';
import apple_icon_cl from '../../../assets/img/header/apple_cl.svg';
import spotify_icon_wh from '../../../assets/img/header/spotify_wh.svg';
import spotify_icon_cl from '../../../assets/img/header/spotify_cl.svg';
import booth_icon_wh from '../../../assets/img/header/booth_wh.svg';
import booth_icon_cl from '../../../assets/img/header/booth_cl.svg';
import cien_icon_wh from '../../../assets/img/header/cien_wh.svg';
import cien_icon_cl from '../../../assets/img/header/cien_cl.svg';
type Props = {
  mode: string
}
const HeaderSns = (props: Props) => {
  return (
    <>
      <div className={"header_sns header_sns_" + props.mode}>
        <a className="icon tw" href="https://twitter.com/NakuruAitsuki" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          <img src={twitter_icon_wh} className="white" alt="twitter" />
          <img src={twitter_icon_cl} className="color" alt="twitter" />
        </a>
        <a className="icon yt" href="https://www.youtube.com/channel/UCQHCHIoeu0FRJTJNMG2_Ezw" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          <img src={youtube_icon_wh} className="white" alt="youtube" />
          <img src={youtube_icon_cl} className="color" alt="twitter" />
        </a>
        <a className="icon am" href="https://music.apple.com/jp/artist/%E8%97%8D%E6%9C%88%E3%81%AA%E3%81%8F%E3%82%8B/1177446629" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          <img src={apple_icon_wh} className="white" alt="booth" />
          <img src={apple_icon_cl} className="color" alt="booth" />
        </a>
        <a className="icon sf" href="https://open.spotify.com/artist/76jymFPvOx0wxV8nEEewSq" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          <img src={spotify_icon_wh} className="white" alt="booth" />
          <img src={spotify_icon_cl} className="color" alt="booth" />
        </a>
        <a className="icon bt" href="https://nakuru.booth.pm/" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          <img src={booth_icon_wh} className="white" alt="booth" />
          <img src={booth_icon_cl} className="color" alt="booth" />
        </a>
        <a className="icon ce" href="https://ci-en.net/creator/7588" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          <img src={cien_icon_wh} className="white" alt="ci-en" />
          <img src={cien_icon_cl} className="color" alt="ci-en" />
        </a>
      </div>
    </>
  );
}
export default HeaderSns; 