import ArticleList from '../../model/news/NewsList'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import { activateHoverCursor, deactivateHoverCursor } from '../../modules/common'
import { useMemo } from 'react';
import useBackground from '../../hooks/useBackground'

type Props = {
}

const Index = (props: Props) => {

  const background = useBackground();

  const index = useMemo(() => {
    return (
      <>
        <Helmet>
          <title>NEWS｜藍月なくる official site【公式サイト】</title>
          <meta name="description" content="お知らせ｜藍月なくるオフィシャルサイト" />
          <meta property="og:url" content="https://aitsukinakuru.com/news" />
          <meta property="og:title" content="NEWS｜藍月なくる official site【公式サイト】" />
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }} // このページに遷移した直後
          animate={{ opacity: 1 }} // 最終的な状態
          exit={{ opacity: 0 }} // 離れる際にどうなるか
          transition={{ duration: 0.5 }} // 切り替えに要する時間
        >
          <main className="p__articles_index l__content_width">
            <h2 className="c__head_primary">NEWS</h2>
            <ArticleList limit={12} pagenate={true} showCategories={true} />
            <p className="c__button_underline">
              <Link to="/" onClick={() => background.deactivate()} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                TOP
              </Link>
            </p>
          </main>
        </motion.div>
      </>
    );
  }, [])

  return (
    <>
      {index}
    </>
  );

}
export default Index;