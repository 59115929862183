import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { loaded, loading, getParam, addClass, activateHoverCursor, deactivateHoverCursor } from '../../modules/common'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';
import { d } from '../../modules/cipher'
import Pagenation from '../../ui/Pagenation'
import CategoriesList from '../category/CategoriesList'
import noimage from '../../assets/img/common/news_noimage-min.jpg'
import useBackground from '../../hooks/useBackground'

type Props = {
  limit: number
  pagenate: boolean
  showCategories: boolean
}

const NewsList = (props: Props) => {

  // 背景操作用
  const background = useBackground();

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  // 記事セット
  const [articles, setArticleList] = useState([]);

  // ページング系
  const limit = props.limit;
  const [currentPage, setCurrentPage] = useState(initCurrentPage());
  const [totalPages, setTotalPages] = useState(0);
  const [catId, setCatId] = useState(initCategory());
  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);

  const apiPath = d(process.env.REACT_APP_BACKEND_API_URL);

  // 初回画面表示時に選択されているページ数を取得
  function initCurrentPage(): number {
    let params: number = parseInt(getParam("page"));
    if (params) {
      return params
    } else {
      return 1
    }
  }

  // 初回画面表示時に選択されているカテゴリを取得
  function initCategory(): string {
    let params: string = getParam("category");
    if (params) {
      return params
    } else {
      return ""
    }
  }

  function handleThumbClick(e: any) {
    background.deactivate();
    addClass(e.target, "active"); // アニメーション用
  }

  // 記事一覧を取得してセット
  useEffect(() => {
    loading();
    background.deactivate();
    const categoryParam = catId === "" ? "" : `category=${catId}&`
    const previewQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `news_index/v2?${categoryParam}per_page=${limit}&page=${currentPage}${previewQuery}`
    const uri = apiPath + query;

    axios({
      method: 'get',
      url: uri,
      timeout: 5000
    })
      .then(function (res) {
        setArticleList(res.data.contents);
        setTotalPages(parseInt(res.data.total_pages)); //総ページ数 
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {
        loaded();
        background.activate();
      });
  }, [currentPage, catId, routeUniqueKey]);

  // サムネURLの設定
  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }

  const showArticles = useMemo(() => {
    let postTitle;
    let postUrl;
    let publishedDate;
    let postCategories: any;
    let previewQuery = getParam("previewMode") === "on" ? "?previewMode=on" : "";

    if (articles.length >= 1) {
      return (
        <div className="c__articles_grid">
          {
            articles.map((article: any, index: number) => {
              // タイトルの取得
              postTitle = String(article.title);
              // URLの取得
              postUrl = "/news/" + article.id + previewQuery;
              //公開日
              publishedDate = dayjs(article.date).format('YYYY.MM.DD');
              // カテゴリの取得
              postCategories = article.cat_name
              return (
                <div key={index}>
                  <Link to={postUrl} className="thumbnail_anchor" title={postTitle} onClick={(e) => handleThumbClick(e)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                    <img src={img_src(article)} alt={postTitle} />
                  </Link>
                  <p className="date">{publishedDate}</p>
                  <Link to={postUrl} className="title" title={postTitle} onClick={() => background.deactivate()} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                    {postTitle}
                  </Link>
                  <span className="title_border"></span>
                  <p className="category">{"#" + postCategories}</p>
                </div>
              )
            })}
        </div>
      )
    } else {
      return (
        <p className="article_notfound c__common_text">
          <span className="u__ib">データが見つかりませんでした。</span>
          <span className="u__ib">他のカテゴリでお探しください。</span>
        </p>
      )
    }
  }, [articles])

  return (
    <>
      {props.showCategories &&
        <CategoriesList categoryTaxonomy="categories" catId={catId} setCatId={setCatId} setCurrentPage={setCurrentPage} />}
      <div className="js__load_required" key={routeUniqueKey}>
        {showArticles}
      </div>
      {props.pagenate && totalPages >= 2 &&
        <Pagenation
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          catId={catId} />}
    </>
  );
}

export default NewsList;