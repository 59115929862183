import { Helmet } from "react-helmet-async"
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { activateHoverCursor, deactivateHoverCursor } from '../../modules/common'
import useBackground from '../../hooks/useBackground'

type Props = {
}

const Submitted = (props: Props) => {

  /*=======================================
    背景操作用
  =======================================*/
  const background = useBackground();
  useEffect(() => {
    background.activate();
  }, []);

  return (
    <>
      <Helmet>
        <title>送信完了｜藍月なくる official site【公式サイト】</title>
        <meta name="description" content={"送信完了｜藍月なくるオフィシャルサイト"} />
        <meta property="og:url" content={"https://aitsukinakuru.com/contact/submitted"} />
        <meta property="og:title" content={"送信完了｜藍月なくる official site【公式サイト】"} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
      <main className="p__contact_submitted l__content_width">
        <h2 className="c__head_primary">CONTACT</h2>
        <p className="c__head_description">
          この度はお問い合わせありがとうございました。<br />
          3日以内に事務所のマネージャーよりお返事いたしますので、しばらくお待ちください。<br />
          返事がない場合は、大変お手数ですが再度お問い合わせいただきますよう、お願い申し上げます。
        </p>
          <Link to={"/"} className="c__submit_primary" id="submit" onClick={() => background.deactivate()} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          TOPへ戻る
        </Link>
      </main>
    </motion.div>

    </>
  );
}
export default Submitted;