import { Link } from 'react-router-dom';
import useBackground from '../hooks/useBackground'
import { activateHoverCursor, deactivateHoverCursor } from '../modules/common'
type Props = {
  path: string
  external: boolean
}

const LinkButtonPlus = (props: Props) => {

  const background = useBackground();

  function link() {
    if (props.external) {
      return (
        <a className="c__plus_button" href={props.path} target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}></a>
      )
    } else {
      return (
        <Link className="c__plus_button" to={props.path} onClick={() => background.deactivate()} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}></Link>
      )
    }
  }
  return (
    <>
      {link()}
    </>
  );
}
export default LinkButtonPlus;