import { useEffect } from 'react';
import { qs } from '../modules/common'
import { isMobile } from 'react-device-detect';
const MouseStalker = () => {

  let stalker: any;
  useEffect(() => {
    if (!isMobile) {
      stalker = qs('#mouse_stalker');
      document.addEventListener('mousemove', stalk);
    }
    return () => {
      document.removeEventListener('mousemove', stalk);
    }
  }, []);

  function stalk(e: any) {
    stalker.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
  }

  return (
    <>
      {/* {(!isMobile) &&
        <div id="mouse_stalker" style={{ transform: "translate(0, 0)" }}></div>
      } */}
    </>
  );
}
export default MouseStalker;