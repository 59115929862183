import { useLocation } from 'react-router-dom';

const useCurrentPage = (): string => {

  const location = useLocation();
  const pathHash = location.pathname + location.hash

  if (pathHash.includes("news")) {
    return "news"
  } else if (pathHash.includes("profile")) {
    return "profile"
  } else if (pathHash.includes("discography")) {
    return "discography"
  } else if (pathHash.includes("contact")) {
    return "contact"
  } else {
    return "home"
  }
}
export default useCurrentPage;