import { Routes, Route, useLocation } from 'react-router-dom';
import Home from '../pages/home/Index';
import Contact from '../pages/contact/Index';
import ContactSubmitted from '../pages/contact/Submitted';
import ArticlesIndex from '../pages/news/Index';
import ArticlesShow from '../pages/news/Show';
import DiscographyIndex from '../pages/discography/Index';
import DiscographyShow from '../pages/discography/Show';
import NotFound from '../pages/common/Notfound';
import { AnimatePresence } from "framer-motion";

type Props = {
}

function Router(props: Props) {
  const location = useLocation();
  return (
    <>
      <AnimatePresence mode="wait" initial={true} onExitComplete={() => window.scrollTo({top: 0}) }>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<ArticlesIndex />} />
          <Route path="/news/:id" element={<ArticlesShow />} />
          <Route path="/discography" element={<DiscographyIndex />} key={"DiscographyIndex"} />
          <Route path="/discography/:id" element={<DiscographyShow />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/submitted" element={<ContactSubmitted />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};
export default Router;