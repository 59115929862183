import { useEffect, CSSProperties, useState } from 'react';
import awa_01 from '../../../assets/img/common/background/awa_01.png'
import awa_02 from '../../../assets/img/common/background/awa_02.png'
import awa_03 from '../../../assets/img/common/background/awa_03.png'
import awa_04 from '../../../assets/img/common/background/awa_04.png'
import awa_05 from '../../../assets/img/common/background/awa_05.png'
import awa_06 from '../../../assets/img/common/background/awa_06.png'
import useBackground from '../../../hooks/useBackground'
import useCurrentPage from '../../../hooks/useCurrentPage'
import { qs } from '../../../modules/common'

type Props = {
}

let parallaxTarget: any;
let windowHeight: number;
let parallaxMagnification: number;

const BigBubbles = (props: Props) => {

  const background = useBackground();
  const currentPage = useCurrentPage();

  // 生成上限
  const maxNum = 15;

  const [bigBubbles, setbigBubbles] = useState(generateBigBubble());

  // backgroundのstateが切り替わると発火
  useEffect(() => {
    if (background.isActive) {
      setbigBubbles(generateBigBubble());
    }
  }, [background.isActive]);

  useEffect(() => {
    parallaxTarget = qs('#parallax');
    windowHeight = window.innerHeight;
    parallaxMagnification = 4000 > parallaxTarget.offsetHeight ? 0.2 : 0.04 // ページの高さが一定以上だった場合は倍率を変える
    window.addEventListener("scroll", parallax);
    return () => {
      window.removeEventListener("scroll", parallax)
    }
  }, []);

  // パララックス処理本体
  function parallax() {
    parallaxTarget.style.transform = `translateY(-${window.pageYOffset * parallaxMagnification}px)`
  }

  function getRandomImg() {
    const num: number = getRandomNum(1, 6);
    if (num === 1) {
      return awa_01
    } else if (num === 2) {
      return awa_02
    } else if (num === 3) {
      return awa_03
    } else if (num === 4) {
      return awa_04
    } else if (num === 5) {
      return awa_05
    } else if (num === 6) {
      return awa_06
    } else {
      return awa_01
    }
  }

  // ランダムな整数を生成
  function getRandomNum(min: number, max: number, offset: number = 0) {
    const num = (Math.floor(Math.random() * (max + 1 - min)) + min) + offset;
    return num <= 0 ? 0 : num;
  }

  function generateBigBubble() {
    return (
      [...Array(maxNum)].map((_, index: number) => {
        const i = index + 1
        const randomNumY = i * 2 * 150 - getRandomNum(100, 170); // px
        const randomNumX = i % 2 ? getRandomNum(1, 13) : 95 - getRandomNum(1, 15); // vw
        const size = getRandomNum(10, 13, -0.65 * i); // vw
        const opacity = getRandomNum(6, 9) / 10;
        const blur = getRandomNum(0, 3, -1) * 1.5; // px
        const delay = blur;
        const duration = getRandomNum(17, 26); // s
        const style: CSSProperties = (
          {
            position: 'absolute',
            top: `${randomNumY}px`,
            left: `${randomNumX}vw`,
            width: `clamp(50px ,${size}vw, 180px)`,
            opacity: `${opacity}`,
            filter: `blur(${blur}px)`,
            animation: `${duration}s ease ${delay}s infinite normal none running bubble`,
          }
        )
        return (
          <img key={i} src={getRandomImg()} style={style} className={`bubbles ${background.isActive ? " active" : ""}`} alt="" />
        )
      })
    )
  }

  return (
    <>
      <div id="big_bubbles_wrapper" className={background.isActive && currentPage !== "contact" ? "active" : ""}>
        <div id="parallax">
          {bigBubbles.map((bubble: any) => {
            return bubble;
          })}
        </div>
      </div>
      <style>
        {`
          @keyframes bubble {
            0% {
              transform: translate(0, 0);
            }
            50% {
              transform: translate(0, 80px);
            }
            100% {
              transform: translate(0, 0);
            }
          }
      `}
      </style>
    </>
  );
}
export default BigBubbles;