import { useEffect } from 'react';
import { activateHoverCursor, deactivateHoverCursor } from '../../modules/common'
import kaitei_l from '../../assets/img/footer/kaitei_l.png'
import kaitei_r from '../../assets/img/footer/kaitei_r.png'
import useBackground from '../../hooks/useBackground'

const Footer = () => {

  const background = useBackground();

  return (
    <>
      <footer id="footer" className={background.isActive ? " active" : ""}>
        <p className="copyright">©︎ 2023 <a href="https://hifumi-inc.co.jp/" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>hifumi.inc</a> All Rights Reserved.</p>
      </footer>
      <div id="footer_background" className={background.isActive ? " active" : ""}>
        <img src={kaitei_l} className={"kaitei_l"} alt="" />
        <img src={kaitei_r} className={"kaitei_r"} alt="" />
        <div className="deep_sea_gradation"></div>
      </div>
    </>
  );
}
export default Footer;