import { useEffect } from 'react';
import { qs, qsAll } from '../../../modules/common'

const SmallBubbles = () => {

  useEffect(() => {
    generateSmallBubbles();
  }, []);

  function generateSmallBubbles() {

    /*=======================================
      パラメーター設定
   =======================================*/

    // 実行間隔(ms)
    const intervalMs = 550;

    // 描画を許可する座標の横の範囲(vw)
    const randomRangeMinX = 4;
    const randomRangeMaxX = 96;

    // 描画を許可する座標の縦の範囲(vw)
    const randomRangeMinY = 40;
    const randomRangeMaxY = 105;

    // 雪のサイズ範囲(px)
    const snowSizeMin = 2;
    const snowSizeMax = 5;

    // durationの範囲(s)
    const durationMin = 5;
    const durationMax = 8;

    // 線生成数上限
    const counterMax = 30;

    // 変数の初期化
    let i = 0;
    let randomNumY = 0;
    let randomNumX = 0;
    let size = 0;
    let duration = 0;

    /*=======================================
      実際の処理
    =======================================*/

    // 描画エリアの設定
    const drawArea = qs('#smallBubble');

    // 描画用のdivを定義
    let bubbleDot = (top: number, left: number, n: number, size: number, duration: number) => `<span class="bubble_dot n${n}" style="top: ${top}%; left: ${left}%;width: ${size}px; height: ${size}px; animation-duration: ${duration}s;"></span>`;

    // 処理本体
    function drawSmallBubble() {

      // 線の生成数が設定値の上限に達していたらカウンターをリセット
      if (i >= counterMax) {
        i = 0;
      }

      // 古くなった既存の線を削除
      let oldestBubble = qsAll(`.bubble_dot.n${i}`);
      if (oldestBubble) {
        for (const elm of oldestBubble) {
          elm.remove();
        }
      }

      // 生成したY軸X軸の乱数を基に新しい雪を生成、雪のサイズもここで決める
      // 座標や雪のサイズ、アニメーションの長さの乱数を生成
      randomNumY = getRandomNum(randomRangeMinY, randomRangeMaxY);
      randomNumX = getRandomNum(randomRangeMinX, randomRangeMaxX);
      size = getRandomNum(snowSizeMin, snowSizeMax);
      duration = getRandomNum(durationMin, durationMax);
      drawArea.insertAdjacentHTML("beforeend", bubbleDot(randomNumY, randomNumX, i, size, duration));
      i++;
    };

    // 処理本体を実行する間隔を設定
    // const interval = setInterval(drawSmallBubble, intervalMs);
    const intervalArr = new Array();
    intervalArr.push(setInterval(drawSmallBubble, intervalMs));

    // ブラウザのタブが非アクティブになったら動作を停止し、アクティブになったら初期化して再実行
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "hidden") {
        clearInterval(intervalArr.shift());
        drawArea.innerHTML = "";
      } else {
        i = 0;
        clearInterval(intervalArr.shift());
        intervalArr.push(setInterval(drawSmallBubble, intervalMs));
      }
    });

    // ランダムな整数を生成
    function getRandomNum(min: number, max: number) {
      return Math.floor(Math.random() * (max + 1 - min)) + min;
    }
  }

  return (
    <>
      <div id="smallBubble"></div>
    </>
  );
}
export default SmallBubbles;