import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { useEffect, useState } from 'react';
import { activateHoverCursor, deactivateHoverCursor } from '../../modules/common'
import { qs, getHeaderHeight, scrollTo } from '../../modules/common'
import { isInvalidPresence, isInvalidLength, isInvalidChoice, isInvalidEmail, isInvalidJapanese } from '../../modules/validate'
import LinkButton from '../../ui/LinkButtonKirakira'
import useBackground from '../../hooks/useBackground'

type Props = {
}

const Index = (props: Props) => {

  let hasError: boolean = false;
  let errorInputs: Array<String> = [];

  const navigate = useNavigate();

  /*=======================================
    背景操作用
  =======================================*/
  const background = useBackground();

  useEffect(() => {
    background.activate();
  }, []);

  /*=======================================
    お問い合わせフォーム用
  =======================================*/
  const [errorExistenseMessage, seterrorExistenseMessage] = useState("");
  const [errorInputMessages, seterrorInputMessages] = useState([]);
  const [organizationErrorMessage, setOrganizationErrorMessage] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [categoryErrorMessage, setCategoryErrorMessage] = useState("");
  const [detailsErrorMessage, setDetailsErrorMessage] = useState("");
  const [agreementErrorMessage, setAgreementErrorMessage] = useState("");

  const radio_categories: Array<String> = [
    "音源制作について",
    "イベント出演について",
    "プロモーション・コラボについて",
    "音源利用について",
    "その他"
  ]

  function validateOrganization(value: string) {
    let msg = [];
    if (isInvalidLength(value, 50)) {
      msg.push("50文字以内でご入力ください。");
      hasError = true;
      pushErrorInputs("企業名・団体名")
    };
    setOrganizationErrorMessage(joinedErrMsg(msg));
  }

  function validateName(value: string) {
    let msg = [];
    if (isInvalidPresence(value)) {
      msg.push("担当者名をご入力ください。");
      hasError = true;
      pushErrorInputs("担当者名")
    };
    if (isInvalidLength(value, 50)) {
      msg.push("50文字以内でご入力ください。");
      hasError = true;
      pushErrorInputs("担当者名")
    };
    setNameErrorMessage(joinedErrMsg(msg));
  }

  function validateEmail(value: string) {
    let msg = [];
    if (isInvalidPresence(value)) {
      msg.push("メールアドレスをご入力ください。");
      hasError = true;
      pushErrorInputs("メールアドレス")
    } else {
      // 存在した場合のみ
      if (isInvalidLength(value, 256)) {
        msg.push("256文字以内でご入力ください。");
        hasError = true;
        pushErrorInputs("メールアドレス")
      };
      if (isInvalidEmail(value)) {
        msg.push("メールアドレスの形式をご確認ください。");
        hasError = true;
        pushErrorInputs("メールアドレス")
      };
    }
    setEmailErrorMessage(joinedErrMsg(msg));
  }

  function validateCategory(value: string) {
    let msg = [];
    if (value.length && isInvalidChoice(value, radio_categories)) {
      msg.push("選択肢からお選びください。");
      hasError = true;
      pushErrorInputs("お問い合わせの種類")
    };
    setCategoryErrorMessage(joinedErrMsg(msg));
  }

  function validateDetails(value: string) {
    let msg = [];
    if (isInvalidPresence(value)) {
      msg.push("詳しいお問い合わせ内容をご入力ください。");
      hasError = true;
      pushErrorInputs("詳しいお問い合わせ内容")
    } else {
      if (isInvalidJapanese(value)) {
        msg.push("スパム対策のため、最低10文字以上の連続した日本語をご入力ください。");
        hasError = true;
        pushErrorInputs("詳しいお問い合わせ内容");
      };
      if (isInvalidLength(value, 1000)) {
        msg.push("1000文字以内でご入力ください。");
        hasError = true;
        pushErrorInputs("詳しいお問い合わせ内容")
      };
    }
    setDetailsErrorMessage(joinedErrMsg(msg));
  }

  function validateAgreement(checked: string) {
    let msg = [];
    if (!checked) {
      msg.push("プライバシーポリシーへのご同意をお願いします。");
      hasError = true;
      pushErrorInputs("プライバシーポリシー")
    };
    setAgreementErrorMessage(joinedErrMsg(msg));
  }
  function joinedErrMsg(msg: Array<String>) {
    return msg.join("また、");
  }
  function pushErrorInputs(msg: string) {
    if (!errorInputs.includes(msg)) errorInputs.push(msg);
  }

  function encode(data: any) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleSubmit(e: any) {

    e.preventDefault();

    hasError = false;
    errorInputs = [];

    let organizationValue = qs("#organization").value;
    validateOrganization(organizationValue);

    let nameValue = qs("#name").value;
    validateName(nameValue);

    let emailValue = qs("#email").value;
    validateEmail(emailValue);

    let categoryInput = qs(".category_radio:checked");
    let categoryValue = categoryInput ? categoryInput.value : "";
    validateCategory(categoryValue);

    let detailsValue = qs("#details").value;
    validateDetails(detailsValue);

    let agreementValue = qs("#agreement").checked;
    validateAgreement(agreementValue);

    if (hasError) {
      seterrorExistenseMessage("入力内容をご確認ください。");
      seterrorInputMessages(errorInputs);

      let target = qs("#js_err_existence");
      let offset = window.pageYOffset + target.getBoundingClientRect().top - getHeaderHeight();
      scrollTo(offset);

      return;
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", "organization": organizationValue, "name": nameValue, "email": emailValue, "category": categoryValue, "details": detailsValue })
    })
      .then(response => {
        background.deactivate()
        navigate('submitted')
      })
      .catch(function (error) {
        alert("お問い合わせの送信に失敗しました。恐れ入りますが、しばらく時間を置いてもう一度お試しください。");
      })
  };

  // テキストエリア自動伸縮
  function handleTextareaInput(e: any) {
    let offset = 10
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight + offset}px`;
  }

  return (
    <>
      <Helmet>
        <title>CONTACT｜藍月なくる official site【公式サイト】</title>
        <meta name="description" content={"お問い合わせ｜藍月なくるオフィシャルサイト"} />
        <meta property="og:url" content={"https://aitsukinakuru.com/contact/"} />
        <meta property="og:title" content={"お問い合わせ｜藍月なくる official site【公式サイト】"} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >

        <main className="p__contact l__content_width">
          <h2 className="c__head_primary">CONTACT</h2>
          <p className="c__head_description">
            <span className="u__ib">事務所のマネージャーより</span><span className="u__ib">お返事申し上げます。</span><br />
            <span className="u__ib">楽しいお誘いお待ちしております。</span><br />
            <span className="u__ib">二次創作利用ガイドに関するQ＆Aは</span><span className="u__ib">以下よりご確認ください。</span>
          </p>

          <h3 className="fanfic_policy">
            <LinkButton path="https://boulder-aurora-84e.notion.site/4cb395a385cb4897a5a0c9d3a5563804" letter="二次創作利用ガイド" external={true} />
          </h3>

          <form name="contact" method="post">

            <p id="js_err_existence" className="c__error_message">{errorExistenseMessage}<br />
              {errorInputMessages.map((error: any, index: number) => {
                return (
                  <span key={index} className="u__error_list">{"・" + error}</span>
                )
              })}
            </p>

            <div className="input_wrapper">
              <label className="c__contact_label">企業名・団体名</label>
              <input type="text" id="organization" name="organization" className="c__contact_textbox" maxLength={50} placeholder="例）株式会社一二三" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} />
              <p id="organizationErrorMessage" className="c__error_message">{organizationErrorMessage}</p>
            </div>


            <div className="input_wrapper">
              <label className="c__contact_label required">担当者名</label>
              <input type="text" id="name" name="name" className="c__contact_textbox" maxLength={60} placeholder="例）藍月なくる" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} />
              <p id="nameErrorMessage" className="c__error_message">{nameErrorMessage}</p>
            </div>


            <div className="input_wrapper">
              <label className="c__contact_label required">メールアドレス</label>
              <input type="email" id="email" name="email" className="c__contact_textbox" maxLength={254} placeholder="例）xxx@xxx.com" data-conv-half-alphanumeric="true" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} />
              <p id="emailErrorMessage" className="c__error_message">{emailErrorMessage}</p>
            </div>

            <div className="input_wrapper">
              <label className="c__contact_label">お問い合わせの種類</label>
              <div id="radio_wrapper">
                {radio_categories.map((category: any, index: number) => {
                  return (
                    <div key={index} className="radio_form">
                      <input type="radio" id={"c" + index} name="category" value={category} className="c__contact_radio category_radio" />
                      <label htmlFor={"c" + index} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>{category}</label>
                    </div>
                  )
                })}
                <p id="categoryErrorMessage" className="c__error_message">{categoryErrorMessage}</p>
              </div>
            </div>

            <div className="input_wrapper">
              <label className="c__contact_label required">詳しいお問い合わせ内容</label>
              <textarea id="details" name="details" className="c__contact_textarea" maxLength={1000} placeholder="詳しいお問い合わせ内容をご入力ください。" onInput={(e) => handleTextareaInput(e)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}></textarea>
              <p id="detailsErrorMessage" className="c__error_message">{detailsErrorMessage}</p>
            </div>

            <div className="agreement input_wrapper">
              <p className="c__head_description u__agreement_notice">
                <span className="u__ib">お問い合わせフォームをご利用の際は、</span><br />
                <span className="u__ib"><a className="c__external_link" href="https://boulder-aurora-84e.notion.site/f072a546aaf848a4b8ca51dc1474c46c" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>プライバシーポリシー</a>を</span>
                <span className="u__ib">ご一読ください。</span>
              </p>
              <input type="checkbox" id="agreement" name="agreement" value="agreed" className="c__contact_checkbox" />
              <label htmlFor="agreement" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>同意する</label>
              <p id="agreementErrorMessage" className="c__error_message">{agreementErrorMessage}</p>
            </div>

            <button className="c__submit_primary" type="submit" id="submit" onClick={handleSubmit} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>送信する</button>

          </form>

        </main>


      </motion.div>
    </>
  );
}
export default Index;