import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { loaded, loading, activateHoverCursor, deactivateHoverCursor, addStalkerHoverEvent, removeStalkerHoverEvent } from '../../modules/common'
import axios from 'axios';
import { d } from '../../modules/cipher'
import noimage from '../../assets/img/common/news_noimage-min.jpg'
import ogp from '../../assets/img/common/ogp.jpg'
import LinkButtonPlus from '../../ui/LinkButtonPlus'
import useBackground from '../../hooks/useBackground'

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import fv_frame_pc from '../../assets/img/home/first_view/frame_pc_01.svg'
import fv_frame_sp from '../../assets/img/home/first_view/frame_sp_01_2.svg'
import fv_frame_tab from '../../assets/img/home/first_view/frame_sp_02_2.svg'

type Props = {
}

const FirstView = (props: Props) => {

  /*=======================================
    マウスストーカー用
  =======================================*/
  const selector: string = ".fv_swiper a"
  useEffect(() => {
    addStalkerHoverEvent(selector, "stalker_cursor_pointer");
    return () => {
      removeStalkerHoverEvent(selector, "stalker_cursor_pointer");
    }
  }, []);

  const background = useBackground();

  // 記事セット
  const limit = 6;
  const [articles, setArticleList] = useState([]);
  const apiPath = d(process.env.REACT_APP_BACKEND_API_URL);

  // 記事一覧を取得してセット
  useEffect(() => {
    loading();
    const query = `sticky_posts?limit=${limit}`; // 固定の投稿が何もない場合は最新の6件が返ってくる
    const uri = apiPath + query;
    axios({
      method: 'get',
      url: uri,
      timeout: 2000
    })
      .then(function (res) {
        setArticleList(res.data);
      })
      .catch(function (error) {
        // articlesがゼロ件のためキービジュが表示される
      })
      .finally(function () {
        loaded();
      });
  }, []);

  // サムネURLの設定
  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }

  function showArticles(): any {
    let postTitle;
    let postUrl;
    // let imgSrc;
    if (articles.length) {
      return (
        articles.map((article: any, index: number) => {
          postTitle = article.title; // タイトルを設定
          postUrl = "/news/" + article.id; // URLを設定
          return (
            <SwiperSlide key={index}>
              <Link to={postUrl} className="thumbnail_anchor" title={postTitle} onClick={() => background.deactivate()} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                <img src={img_src(article)} alt={postTitle} />
              </Link>
            </SwiperSlide>
          )
        })
      )
    }
    return (
      <SwiperSlide>
        <div className="thumbnail_anchor disabled">
          <img src={ogp} alt={"キービジュアル"} />
        </div>
      </SwiperSlide>
    )
  }

  return (
    <>
      <div className="js__load_required home_first_view">
        <div className="fv_slider_wrapper">
          <img src={fv_frame_pc} alt="" className="fv_frame fv_frame_pc" />
          <img src={fv_frame_tab} alt="" className="fv_frame fv_frame_tab" />
          <img src={fv_frame_sp} alt="" className="fv_frame fv_frame_sp" />
          <Swiper
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            centeredSlides={true}
            effect={"fade"}
            modules={[Pagination, EffectFade, Autoplay]}
            loop={true}
            slidesPerView={1}
            pagination={{ clickable: true, }}
            className="fv_swiper"
          >
            {articles.length >= 1 &&
              <h3 className="fv_head">NEWS</h3>}
            <div className="news_index_link">
              <LinkButtonPlus path="news" external={false} />
            </div>
            {showArticles()}
          </Swiper>
        </div>
      </div>
      <div id="scrolldown" className="js__load_required">
        <span className="first circle"></span>
        <span className="second circle"></span>
        <span className="third circle"></span>
        <span className="fourth circle"></span>
        <span className="arrow"></span>
      </div>
    </>
  );
}

export default FirstView;