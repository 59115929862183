import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Router from '../../../config/Router';
import Header from '../header/Header';
import Footer from '../Footer';
import BigBubbles from "./BigBubbles";
import SmallBubbles from "./SmallBubbles";
import useBackground from '../../../hooks/useBackground'

const Background = () => {

  const background = useBackground();

  // 戻るボタンが押されたら背景をdeactivate
  useEffect(() => {
    window.addEventListener('popstate', () => { background.deactivate() })
    return () => {
      window.removeEventListener('popstate', () => { background.activate() })
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <div id="background" className={background.isActive ? "active" : ""}>
          <div id="yeild" >
            <Header />
            <Router />
            <Footer />
          </div>
          <BigBubbles />
          <SmallBubbles />
        </div>
      </BrowserRouter>
    </>
  );
}
export default Background;