const shift = 3;
// export function c(arg: string): string {
//   let s = "";
//   for (var i = 0; i < arg.length; i++) {
//     s += String.fromCharCode(arg.charCodeAt(i) + shift);
//   }
//   console.log(s);
//   return s;
// }
export function d(arg: string): string {
  let s = "";
  for (var i = 0; i < arg.length; i++) {
    s += String.fromCharCode(arg.charCodeAt(i) - shift);
  }
  return s;
}