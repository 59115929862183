import aitsuki_nakuru from '../../assets/img/home/profile/aitsuki_nakuru.svg'
import aitsuki_nakuru_kanji from '../../assets/img/home/profile/aitsuki_nakuru_kanji.svg'
import endorfin from '../../assets/img/home/profile/endorfin.svg'
import nakuru_1 from '../../assets/img/home/profile/nakuru_1-min.png'
import nakuru_2 from '../../assets/img/home/profile/nakuru_2-min.png'
import nakuru_3 from '../../assets/img/home/profile/nakuru_3-min.png'
import nakuru_4 from '../../assets/img/home/profile/nakuru_4-min.png'
import nakuru_5 from '../../assets/img/home/profile/nakuru_5-min.png'
import nakuru_6 from '../../assets/img/home/profile/nakuru_6-min.png'
import nakuru_7 from '../../assets/img/home/profile/nakuru_7-min.png'
import nakuru_8 from '../../assets/img/home/profile/nakuru_8-min.png'
import nakuru_9 from '../../assets/img/home/profile/nakuru_9-min.png'
import { useEffect } from 'react';
import { ISOvserve, addStalkerHoverEvent, removeStalkerHoverEvent, activateHoverCursor, deactivateHoverCursor } from '../../modules/common'

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import "swiper/css/navigation";

const Profile = () => {

  /*=======================================
    マウスストーカー用
  =======================================*/
  const selector: string = ".swiper-pagination-bullet, .swiper-button-prev, .swiper-button-next"
  useEffect(() => {
    addStalkerHoverEvent(selector, "stalker_cursor_pointer");
    return () => {
      removeStalkerHoverEvent(selector, "stalker_cursor_pointer");
    }
  }, []);

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserve(".home_profile");
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className="home_profile c__js_blur js__transition" id="profile">

        <div className="tab_profile_wrapper">
          <h2 className="c__vertical_head c__js_blur_child delay4 c__vertical_head_pc_none">
            PROFILE
          </h2>
          <div className="profile_wrapper">
            <h3 className="name_en c__js_blur_child">
              <img src={aitsuki_nakuru} alt="Aitsuki Nakuru" width={180} height={19} />
            </h3>
            <h3 className="name_ja">
              <span className="c__kirakira_border"></span>
              <img className="kanji c__js_blur_child delay3" src={aitsuki_nakuru_kanji} alt="藍月なくる" />
              <span className="c__kirakira_border"></span>
            </h3>
            <div className="description_wrapper">
              <h2 className="c__vertical_head c__js_blur_child delay4  c__vertical_head_pc_only">
                PROFILE
              </h2>
              <div className="description c__js_blur_child delay5">
                <p className="c__common_text profile_text">
                  リアルとバーチャルを行き来する、インターネット発のシンガー。<br />
                  透き通る繊細な声が特徴で、音楽ゲーム収録曲の歌唱を多数担当するほか、美少女ゲームでは主題歌の歌唱のみならず声優も務める。<br />
                  sky_deltaとの音楽ユニットEndorfin.ではボーカルを担当。<br />
                  幅広いジャンルの楽曲を、表現力豊かに歌い上げる。<br />
                  現在は音楽活動を中心としながら配信活動にも取り組み、活動の幅を広げている。
                </p>
                <p className="c__common_text links">
                  <span className="service">
                    <a href="https://twitter.com/NakuruAitsuki" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>Twitter</a>
                  </span>
                  <span className="service">
                    <a href="https://www.youtube.com/channel/UCQHCHIoeu0FRJTJNMG2_Ezw" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>YouTube</a>
                  </span>
                </p>
                <h3 className="endorfin_head"><img src={endorfin} alt="Endorfin." /></h3>
                <p className="c__common_text links">
                  <span className="service">
                    <a href="https://twitter.com/endorfin31" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>Twitter</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <Swiper
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
          centeredSlides={true}
          effect={"fade"}
          modules={[Pagination, EffectFade, Autoplay, Navigation]}
          loop={true}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true, }}
          className="profile_swiper c__js_blur_child delay3"
        >
          <span className="bg c__js_blur_child delay2"></span>
          <SwiperSlide>
            <img src={nakuru_1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={nakuru_2} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={nakuru_3} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={nakuru_4} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={nakuru_5} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={nakuru_6} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={nakuru_7} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={nakuru_8} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={nakuru_9} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

export default Profile;