import { isMobile } from 'react-device-detect';
const pc_header_selector = ".header_inner_pc";
const sp_header_selector = "#header_toggle";

/*=======================================
  IntersectionObserver
=======================================*/
export function ISOvserve(rootSelector: string = ""): IntersectionObserver {
  let observer: IntersectionObserver;
  const options = {
    rootMargin: "0% 0% -45% 0%", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  };
  const targetClasses = [".c__js_fade", ".c__js_blur", ".js__transition", ".js__animation"];
  let target = targetClasses.map(function (targetClass) {
    return rootSelector + targetClass;
  });
  const targets = qsAll(target.join(", "));
  if (targets) {
    observer = new IntersectionObserver(callback, options);
    for (const elm of targets) {
      observer.observe(elm);
    }
    function callback(entries: any) {
      entries.forEach(function (value: any) {
        if (value.isIntersecting) {
          value.target.classList.add("active");
        }
      });
    }
  }
  return observer;
}

/*=======================================
  その他関数
=======================================*/
export function setSvh(): void {
  setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--svh', `${vh}px`)
  }, 500);
}
export function setStaticSvh(): void {
  setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--static_svh', `${vh}px`);
  }, 500);
}
export function toggleClass(element: any, className: string): void {
  if (element.className.includes(className)) {
    element.classList.remove(className);
  } else {
    element.classList.add(className);
  }
}
export function scrollTo(offset: number): void {
  window.scrollTo({
    top: offset,
    behavior: "smooth"
  });
}
export function getHeaderHeight() {
  const pcHeaderHeight = qs(pc_header_selector).offsetHeight;
  const tabHeaderHeight = qs(sp_header_selector).offsetHeight;
  return pcHeaderHeight + tabHeaderHeight + 40;
}
export function getParam(param: string): string {
  // 存在しなかった場合はnullを返却
  let url = new URL(window.location.href);
  return url.searchParams.get(param);
}
export function getPath(): string {
  return window.location.pathname;
}
export function getHash(): string {
  return window.location.hash;
}
export function getPathHash(): string {
  return window.location.pathname + window.location.hash;
}
export function isActive(element: any): boolean {
  return element.className.includes("active");
}
export function qs(name: string, elm = document): any {
  return elm.querySelector<HTMLElement>(name);
}
export function qsAll(name: string, elm = document): any {
  return elm.querySelectorAll(name);
}
export function hasClass(elm: any, name: string): any {
  return elm.className.includes(name);
}
export function addClass(elm: any, name: string): any {
  if (elm) elm.classList.add(name);
}
export function removeClass(elm: any, name: string): any {
  if (elm && hasClass(elm, name)) {
    elm.classList.remove(name);
  }
}
export function loaded(): void {
  let loader = qsAll(".js__load_required");
  for (const elm of loader) {
    addClass(elm, "loaded");
  }
}
export function loading(): void {
  let loader = qsAll(".js__load_required");
  for (const elm of loader) {
    removeClass(elm, "loaded");
  }
}

/*=======================================
  マウスストーカー用
=======================================*/
export function addStalkerHoverEvent(selector: string, addingClass: string, waitTime: number = 1500): void {
  setTimeout(() => {
    let links = qsAll(selector);
    if (links) {
      let target = qs("#mouse_stalker")
      for (const elm of links) {
        elm.addEventListener('mouseover', function () {
          addClass(target, addingClass);
        });
        elm.addEventListener('mouseout', function () {
          removeClass(target, addingClass);
        });
      }
    }
  }, waitTime);
}
export function removeStalkerHoverEvent(selector: string, addingClass: string): void {
  removeClass(qs("#mouse_stalker"), "stalker_cursor_pointer");
  let links = qsAll(selector);
  if (links) {
    let target = qs("#mouse_stalker")
    for (const elm of links) {
      elm.removeEventListener('mouseover', function () {
        addClass(target, addingClass);
      });
      elm.removeEventListener('mouseout', function () {
        removeClass(target, addingClass);
      });
    }
  }
}
export function activateHoverCursor(): void {
  if (!isMobile) {
    addClass(qs("#mouse_stalker"), "stalker_cursor_pointer");
  }
}
export function deactivateHoverCursor(): void {
  if (!isMobile) {
    removeClass(qs("#mouse_stalker"), "stalker_cursor_pointer");
  }
}