import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async"
import useBackground from '../../hooks/useBackground'
import { activateHoverCursor, deactivateHoverCursor } from '../../modules/common'
type Props = {
}

const NotFound = (props: Props) => {

  /*=======================================
    背景操作用
  =======================================*/
  const background = useBackground();

  useEffect(() => {
    background.activate();
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
      <main className="p__notfound l__content_width">
        <h2 className="c__head_primary">404 NOT FOUND</h2>
        <p className="c__head_description">お探しのページが見つかりませんでした。</p>
          <Link to="/" className="c__submit_primary" onClick={() => background.deactivate()} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          TOPへ戻る
        </Link>
      </main>
    </motion.div>
    </>
  );
}
export default NotFound;