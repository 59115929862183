
import './assets/scss/App.scss';
import { setSvh, setStaticSvh } from './modules/common'
import Background from './pages/common/background/Background';
import MouseStalker from './ui/MouseStalker';
import { Helmet } from "react-helmet-async"
import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';

const App = () => {

  useEffect(() => {
    setSvh();
    setStaticSvh();
    window.addEventListener('resize', setSvh);
    return () => {
      window.removeEventListener('resize', setSvh);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title >藍月なくる official site【公式サイト】</title>
        <meta name="description" content="藍月なくるオフィシャルサイト｜リアルとバーチャルを行き来する、インターネット発のシンガー。" />
        <meta property="og:url" content="https://aitsukinakuru.com/" />
        <meta property="og:image" content="https://aitsukinakuru.com/ogp.jpg" />
        <meta property="og:title" content="藍月なくる official site【公式サイト】" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <RecoilRoot>
        <Background />
        <MouseStalker />
      </RecoilRoot>
    </>
  );
}
export default App;