import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import DiscographyList from '../../model/discography/DiscographyList'
import LinkButtonPlus from '../../ui/LinkButtonPlus'
import { useEffect } from 'react';
import { ISOvserve } from '../../modules/common'

type Props = {
}

const Discography = (props: Props) => {

  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserve(".home_discography ");
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="home_discography">
      <div className="discography_wrapper">
        <div className="discography_list c__js_fade delay3">
          <DiscographyList limit={6} pagenate={false} showCategories={false} />
        </div>
        <h2 className="c__vertical_head c__js_fade delay1">
          DISCOGRAPHY
        </h2>
      </div>
      <div className="discography_index_link">
        <LinkButtonPlus path="discography" external={false} />
      </div>
    </div>
  );
}
export default Discography;