import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { qs, scrollTo, getHeaderHeight, toggleClass, hasClass, setSvh, removeClass, addClass, activateHoverCursor, deactivateHoverCursor } from '../../../modules/common'
import HeaderSns from './HeaderSns'
import logo_wh from '../../../assets/img/header/logo_wh.svg'
import logo_cl from '../../../assets/img/header/logo_cl.svg'
import useBackground from '../../../hooks/useBackground'
import useCurrentPage from '../../../hooks/useCurrentPage'

type Props = {
}

let toggle: any;
let sp_menu: any;

const Header = (props: Props) => {

  const background = useBackground();
  const currentPage = useCurrentPage();
  const navigate = useNavigate();

  /*=======================================
    SPメニュー制御
  =======================================*/
  // 要素取得
  useEffect(() => {
    toggle = qs("#header_toggle");
    sp_menu = qs("#sp_menu");
    return arrowScroll();
  }, []);
  // メニュー開閉
  function toggleMenu() {
    setSvh();
    toggleClass(toggle, "active");
    toggleClass(sp_menu, "active");
    if (hasClass(toggle, "active")) {
      preventScroll();
    } else {
      arrowScroll();
    }
  }
  function preventScroll() {
    document.addEventListener("wheel", preventDef, { passive: false });
    document.addEventListener("touchmove", preventDef, { passive: false });
  }
  function arrowScroll() {
    document.removeEventListener("wheel", preventDef);
    document.removeEventListener("touchmove", preventDef);
  }
  function preventDef(e: any) {
    e.preventDefault();
  }

  /*=======================================
    ページトップボタン制御
  =======================================*/
  useEffect(() => {
    const top = qs('#pagetop_observer');
    const pagetopButton = qs('#pagetop_button');
    const options = {
      rootMargin: "170px 0px 0px 0px",
    };
    // ページ上部にあるpagetop_observerとの交差が外れたらactiveにする
    const observer = new IntersectionObserver((entries) => {
      for (const e of entries) {
        if (e.isIntersecting) {
          removeClass(pagetopButton, 'active');
        } else {
          addClass(pagetopButton, 'active');
        }
      }
    }, options);
    observer.observe(top);
    return () => { observer.disconnect(); };
  }, []);
  // ボタンクリック時は一番上にスクロール
  function handlePagetopClick(e: any) {
    e.preventDefault();
    scrollTo(0);
  };

  /*=======================================
    クリック制御
  =======================================*/
  // 背景非活性化
  function handleClickLink(newPageName: string, isSp: boolean) {
    if (currentPage !== newPageName) background.deactivate();
    if (isSp) toggleMenu();
  };
  // アンカーリンク制御
  function scrollToProfile(isSp: boolean, e: any) {
    e.preventDefault();
    if (currentPage === "home" || currentPage === "profile") {
      navigate("/#profile")
      let target = qs("#profile");
      let offset = window.pageYOffset + target.getBoundingClientRect().top - getHeaderHeight() + 30;
      scrollTo(offset);
    } else {
      background.deactivate();
      navigate("/#profile")
      setTimeout(() => {
        let target = qs("#profile");
        let offset = window.pageYOffset + target.getBoundingClientRect().top - getHeaderHeight() + 30;
        scrollTo(offset);
      }, 600);
    }
    if (isSp) toggleMenu();
  }
  // homeへのリンク制御
  function handleClickHome(toggle: boolean, e: any) {
    e.preventDefault();
    if (currentPage === "home" || currentPage === "profile") {
      navigate("/")
      scrollTo(0);
    } else {
      navigate("/")
      background.deactivate();
    }
    if (toggle) toggleMenu();
  };

  return (
    <>
      <header id={"header"}>
        <div className="header_inner_pc">
          <ul className="gnav">
            <li className="logo">
              <Link to="/" onClick={(e) => handleClickHome(false, e)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                <img src={logo_wh} className="white" alt="" width={140} height={61}/>
                <img src={logo_cl} className="color" alt="" width={140} height={61} />
              </Link>
            </li>
            <li>
              <Link to="/news" onClick={() => handleClickLink("news", false)} className={currentPage === "news" ? " active" : ""} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                NEWS
              </Link>
            </li>
            <li>
              <Link to="/#profile" onClick={(e) => scrollToProfile(false, e)} className={currentPage === "profile" ? " active" : ""} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                PROFILE
              </Link>
            </li>
            <li>
              <Link to="/discography" onClick={() => handleClickLink("discography", false)} className={currentPage === "discography" ? " active" : ""} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                DISCOGRAPHY
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={() => handleClickLink("contact", false)} className={currentPage === "contact" ? " active" : ""} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                CONTACT
              </Link>
            </li>
          </ul>
          <HeaderSns mode="pc" />
        </div>

        <div className="sp_header_logo">
          <Link to="/" onClick={(e) => handleClickHome(false, e)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            <img src={logo_wh} className="white" alt="" width={140} height={61} />
          </Link>
        </div>
        <nav id="sp_menu" className="">
          <div className="menu_inner">
            <div className="sp_menu_logo">
              {/* <Link to="/"> */}
              <img src={logo_wh} className="white" alt="" width={140} height={61} />
              {/* </Link> */}
            </div>
            <ul className="gnav">
              <li className="link_wrapper">
                <Link to="/" onClick={(e) => handleClickHome(true, e)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                  <span className="name">HOME</span>
                  <span className="decoration">
                    <span className="line"></span>
                    <span className="kira"></span>
                  </span>
                </Link>
              </li>
              <li className="link_wrapper">
                <Link to="/news" onClick={() => handleClickLink("news", true)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                  <span className="name">NEWS</span>
                  <span className="decoration">
                    <span className="line"></span>
                    <span className="kira"></span>
                  </span>
                </Link>
              </li>
              <li className="link_wrapper">
                <Link to="/#profile" onClick={(e) => scrollToProfile(true, e)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                  <span className="name">PROFILE</span>
                  <span className="decoration">
                    <span className="line"></span>
                    <span className="kira"></span>
                  </span>
                </Link>
              </li>
              <li className="link_wrapper">
                <Link to="/discography" onClick={() => handleClickLink("discography", true)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                  <span className="name">DISCOGRAPHY</span>
                  <span className="decoration">
                    <span className="line"></span>
                    <span className="kira"></span>
                  </span>
                </Link>
              </li>
              <li className="link_wrapper">
                <Link to="/contact" onClick={() => handleClickLink("contact", true)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                  <span className="name">CONTACT</span>
                  <span className="decoration">
                    <span className="line"></span>
                    <span className="kira"></span>
                  </span>
                </Link>
              </li>
            </ul>
            <HeaderSns mode="sp" />
          </div>
        </nav>

        <div id="header_toggle" onClick={toggleMenu} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          <span></span>
          <span></span>
          <span></span>
        </div>

      </header>

      <div id="pagetop_button">
        <a onClick={(e) => { handlePagetopClick(e) }} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}></a>
      </div>
      <div id="pagetop_observer"></div>
    </>
  );
}
export default Header;