import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { motion } from "framer-motion"
import { d } from '../../modules/cipher'
import { Helmet } from "react-helmet-async"
import noimage from '../../assets/img/common/news_noimage-min.jpg'
import { loaded, loading, qs, addStalkerHoverEvent, removeStalkerHoverEvent, getParam } from '../../modules/common'
import useBackground from '../../hooks/useBackground'

type Props = {
}

const Show = (props: Props) => {

  const background = useBackground();

  const { id } = useParams(); //ルーティングで使っているパラメータ
  const [article, setArticle] = useState(newArticle("", "", "", "", "", ""));
  const [prevArticleId, setPrevArticleId] = useState("");
  const [nextArticleId, setNextArticleId] = useState("");

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const apiPath = d(process.env.REACT_APP_BACKEND_API_URL);

  // 記事詳細を取得
  useEffect(() => {
    loading();
    background.deactivate();

    let postTitle: string;
    let publishedDate: string;
    let postCategory: string;
    let postImage: string;
    let postContent: string;
    let excerpt: string;

    const previewQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `news_detail/v2?id=${id}${previewQuery}`;
    const uri = apiPath + query;

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        // タイトルの取得
        postTitle = String(res.data.title);

        //公開日
        publishedDate = dayjs(res.data.date).format('YYYY-MM-DD');

        // カテゴリの取得
        postCategory = res.data.cat_name;

        // 本文の取得
        postContent = res.data.content;

        // サムネイルの取得
        postImage = img_src(res.data);

        excerpt = res.data.excerpt;

        // 記事情報のセット
        setArticle(newArticle(postTitle, publishedDate, postCategory, postImage, postContent, excerpt));
        if (res.data.prev) setPrevArticleId(res.data.prev.id);
        if (res.data.next) setNextArticleId(res.data.next.id);

        loaded();
        background.activate();
      })
      .catch(function (e) {
        loaded();
        background.activate();
        window.location.href = "/notfound"
      })
  }, []);

  /*=======================================
    twitter埋め込み動かない対策
  =======================================*/
  const script = document.createElement('script');
  script.src = "https://platform.twitter.com/widgets.js";
  useEffect(() => {
    let twitter_widget_wrapper = qs("#twitter_widget_wrapper");
    twitter_widget_wrapper.appendChild(script);
    return () => {
      twitter_widget_wrapper.removeChild(script);
    };
  }, [article]);

  function newArticle(title: string, published_at: string, category: string, image_url: string, content: string, excerpt: string) {
    return { title: title, published_at: published_at, category: category, image_url: image_url, content: content, excerpt: excerpt };
  }

  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }
  function prevPager() {
    const cls = prevArticleId === "" ? " disabled" : "";
    return (
      <Link to={`/news/${prevArticleId}`} className={"pager_link pager_link_prev" + cls} onClick={() => background.deactivate()}>
        <span className={"arrow left" + cls}></span>
      </Link>
    )
  }
  function nextPager() {
    const cls = nextArticleId === "" ? " disabled" : "";
    return (
      <Link to={`/news/${nextArticleId}`} className={"pager_link pager_link_next" + cls} onClick={() => background.deactivate()}>
        <span className={"arrow right" + cls}></span>
      </Link>
    )
  }
  function twitterShareHref() {
    const url = encodeURIComponent(window.location.href);
    const truncatedTitle = article.title.length > 34 ? (article.title.substring(0, 34) + "...") : article.title;
    const title = encodeURIComponent(truncatedTitle + "｜NEWS - 藍月なくる official site");
    const hashtag = encodeURIComponent("藍月なくる");
    return (
      `https://twitter.com/share?url=${url}&text=${title}&hashtags=${hashtag}`
    )
  }

  /*=======================================
    マウスストーカー用
  =======================================*/
  // 記事内のリンクにも反応させるためにuseEffectを使用
  const selector: string = ".p__articles_show a"
  useEffect(() => {
    addStalkerHoverEvent(selector, "stalker_cursor_pointer");
    return () => {
      removeStalkerHoverEvent(selector, "stalker_cursor_pointer");
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:image" content={article.image_url}></meta>
        <title>{article.title}｜NEWS - 藍月なくる official site【公式サイト】</title>
        <meta name="description" content={"お知らせ - 藍月なくるオフィシャルサイト｜" + article.excerpt} />
        <meta property="og:url" content={"https://aitsukinakuru.com/news/" + id} />
        <meta property="og:title" content={article.title + "｜NEWS - 藍月なくる official site【公式サイト】"} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <div className="js__load_required">
          <main className="p__articles_show l__content_width">
            <div className="article_head">
              <h2 className="article_title">{article.title}</h2>
              <div className="article_head_info">
                <p className="article_published_at">{article.published_at}</p>
                <p className="article_category">{"#" + article.category}</p>
              </div>
            </div>
            <article className="article_wrapper">
              <div className="article_thumbnail">
                <div className="img_wrapper">
                  <img src={article.image_url} alt="" />
                </div>
              </div>
              <div className="c__article_body" dangerouslySetInnerHTML={{ __html: article.content }} />

              <a className="c__sns_share"
                href={twitterShareHref()} target="_blank" rel="noopener noreferrer"><span className="sns_share_text">share</span></a>
              <div className="article_prev_next">
                <div className="link_wrapper link_wrapper_prev">
                  {prevPager()}
                </div>
                <div className="link_wrapper link_wrapper_next">
                  {nextPager()}
                </div>
              </div>
              <p className="c__button_underline">
                <Link to="/news" onClick={() => background.deactivate()}>
                  ALL
                </Link>
              </p>
            </article>
          </main>
          <div id="twitter_widget_wrapper"></div>
        </div>
      </motion.div>
    </>
  );
}
export default Show;