import { useEffect, useState } from 'react'
import { loaded, loading, activateHoverCursor, deactivateHoverCursor } from '../../modules/common'
import axios from 'axios'
import { d } from '../../modules/cipher'
import useBackground from '../../hooks/useBackground'

type Props = {
  setCatId: any
  setCurrentPage: any
  catId: string
  categoryTaxonomy: string
}

const CategoriesList = (props: Props) => {

  const [categories, setCategories] = useState([])
  const apiPath = d(process.env.REACT_APP_BACKEND_API_URL)

  const background = useBackground();

  // 初回のみ、ページングのヒストリー操作にまつわるヒストリーAPIの初期設定を実行
  useEffect(() => {
    window.addEventListener("popstate", back)
    return () => {
      cleanPopstate()
    }
  }, [])
  function cleanPopstate() {
    window.removeEventListener("popstate", back)
  }

  // ブラウザバック時に発動、ひとつ前の履歴のカテゴリをセット
  function back(e: any) {
    let category: string = window.history.state.category ? window.history.state.category : ""
    props.setCatId(category)
  }

  useEffect(() => {
    loading()
    const query = props.categoryTaxonomy;
    const uri = apiPath + query

    axios({
      method: 'get',
      url: uri,
      timeout: 5000
    })
      .then(function (res) {
        setCategories(res.data)
      })
      .catch(function (error) {
      })
      .finally(function () {
        loaded();
      });
  }, [])

  function handleClick(categoryId: string, e: any) {
    background.deactivate();
    const categoryParam = categoryId == "" ? "" : `&category=${categoryId}`
    props.setCatId(categoryId)
    props.setCurrentPage(1)
    window.history.pushState({ category: categoryId }, '', `?page=${1}${categoryParam}`)
  }

  function showCategories(): any {
    let categoriesss = [<li key="all"><a className={"category_buttons" + (props.catId ? "" : " active")} onClick={(e) => handleClick("", e)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>{"#" + "All"}</a></li>]
    if (categories.length >= 1) {
      return (
        categoriesss.concat(
          categories.map((category: any, index: number) => {
            return (
              <li key={category.id + "category"}><a className={"category_buttons" + (props.catId == category.id ? " active" : "")} onClick={(e) => handleClick(category.id, e)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>{"#" + category.name}</a></li>
            )
          })
        )
      )
    }
  }

  return (
    <ul className="c__categories_list">
      {showCategories()}
    </ul>
  )
}

export default CategoriesList