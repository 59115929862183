import { Link } from 'react-router-dom';
import useBackground from '../hooks/useBackground'
import { activateHoverCursor, deactivateHoverCursor } from '../modules/common'
type Props = {
  path: string
  letter: string
  external: boolean
}
const LinkButtonKirakira = (props: Props) => {

  const background = useBackground();

  function link() {
    if (props.external) {
      return (
        <a href={props.path} target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          {props.letter}
        </a>
      )
    } else {
      return (
        <Link to={props.path} onClick={() => background.deactivate()} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          {props.letter}
        </Link>
      )
    }
  }

  return (
    <div className="c__kirakira_button">
      {link()}
    </div>
  );
}
export default LinkButtonKirakira;