import ReactPaginate from 'react-paginate';
import { useEffect } from 'react';
import { scrollTo, addStalkerHoverEvent, removeStalkerHoverEvent } from '../modules/common'

type Props = {
  totalPages: number
  currentPage: number
  setCurrentPage: any
  catId: string
}
const Pagenation = (props: Props) => {

  /*=======================================
    マウスストーカー用
  =======================================*/
  const selector: string = ".c__pager a"
  useEffect(() => {
    addStalkerHoverEvent(selector, "stalker_cursor_pointer");
    return () => {
      removeStalkerHoverEvent(selector, "stalker_cursor_pointer");
    }
  }, []);

  // 初回のみ、ページングのヒストリー操作にまつわるヒストリーAPIの初期設定を実行
  useEffect(() => {
    window.addEventListener("popstate", back);
    return () => {
      cleanPopstate();
    };
  }, []);
  function cleanPopstate() {
    window.removeEventListener("popstate", back);
  }

  // ブラウザバック時に発動、ひとつ前の履歴のページ番号をセット
  function back(e: any) {
    let current = window.history.state.current ? window.history.state.current : 1
    props.setCurrentPage(current);
  }

  // ページクリック時のイベント
  const handlePaginate = (data: any) => {
    const selected = data.selected;
    props.setCurrentPage(selected + 1);
    const categoryQuery = props.catId ? `&category=${props.catId}` : "";
    window.history.pushState({ current: selected + 1 }, '', `?page=${selected + 1}` + categoryQuery);
    scrollTo(0);
  }

  return (
    <ReactPaginate
      forcePage={props.currentPage - 1}
      containerClassName="c__pager" // ul(pagination本体)
      onPageChange={handlePaginate}
      marginPagesDisplayed={1} //終端に表示する件数
      pageRangeDisplayed={4} //現ページ数の周りに表示する件数
      pageCount={props.totalPages}
      renderOnZeroPageCount={() => null}

      previousLabel=""
      previousClassName="prev" // li
      previousLinkClassName="prev_link"

      nextLabel=""
      nextClassName="next" // li
      nextLinkClassName="next_link"

      pageClassName="" // li
      pageLinkClassName="page_num underline" // a
      activeClassName="" // active.li
      activeLinkClassName="active" // active.li < a
      disabledClassName="disabled" // 先頭 or 末尾に行ったときにそれ以上戻れ(進め)なくする

      // 中間ページの省略表記関連
      breakLabel="......"
      breakClassName=""
      breakLinkClassName="elipsis underline"
    />
  );
}
export default Pagenation;