import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import FirstView from './FirstView';
import Profile from './Profile';
import Discography from './Discography';
import Contact from './Contact';
import { useEffect } from 'react';
import useBackground from '../../hooks/useBackground'

type Props = {
}

const Index = (props: Props) => {

  /*=======================================
    背景操作用
  =======================================*/
  const background = useBackground();

  useEffect(() => {
    background.activate();
  }, []);

  return (
    <>
      <Helmet>
        <title>藍月なくる official site【公式サイト】</title>
        <meta name="description" content="藍月なくるオフィシャルサイト｜リアルとバーチャルを行き来する、インターネット発のシンガー。" />
        <meta property="og:url" content="https://aitsukinakuru.com/" />
        <meta property="og:title" content="藍月なくる official site【公式サイト】" />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main className="p__home">
          <FirstView />
          <Profile />
          <Discography />
          <Contact />
        </main>
      </motion.div>
    </>
  );
}
export default Index;