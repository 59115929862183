import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import kurage from '../../assets/img/home/contact/kurage.png'
import LinkButton from '../../ui/LinkButtonKirakira'
import { useEffect } from 'react';
import { ISOvserve } from '../../modules/common'
import { activateHoverCursor, deactivateHoverCursor } from '../../modules/common'

type Props = {
}

const Contact = (props: Props) => {

  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserve(".home_contact");
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className="home_contact js__transition">
        <div className="kurage_img">
          <img src={kurage} alt="" />
        </div>
        <h2 className="c__head_primary js__transition_child"><span>CONTACT</span></h2>
        <span className="c__kirakira_border js__transition_child"></span>
        <p className="c__common_text medium u__mng  js__transition_child">
          <span className="u__ib">事務所のマネージャーより</span><span className="u__ib">お返事申し上げます。</span>
        </p>
        <p className="c__common_text light u__qa js__transition_child">
          <span className="u__ib">二次創作利用ガイドに関するQ＆Aは</span>
          <span className="u__ib"><a className="c__text_underline" href="https://www.notion.so/4cb395a385cb4897a5a0c9d3a5563804" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>こちら</a>からご確認ください。</span>
        </p>
        <h3 className="contact_link js__transition_child">
          <LinkButton path="contact" letter="お問い合わせ" external={false} />
        </h3>
        <span className="c__kirakira_border js__transition_child"></span>
        <p className="c__common_text light u__fanletter js__transition_child">
          <span className="u__ib">ファンレターに関しては、</span><span className="u__ib">下記までお送りください。</span><br />
          <span className="u__ib">　〒151-0071　</span><span className="u__ib">東京都渋谷区本町1丁目40ｰ14</span><span className="u__ib">　カームコート初台306　</span><br />
          <span className="u__ib">※大きなもの・高価なもの・</span><span className="u__ib">生モノ等はご遠慮ください。</span>
        </p>

      </div>
    </>
  );
}
export default Contact;